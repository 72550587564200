import { h } from 'preact'

import GridList, { GridListEntry } from '../atom/GridList'
import ImageArticle, { GenerateImageArticleCollection } from '../atom/ImageArticle'

import { AsSiteContentEntry } from './SiteContent'

const ArticleHighlights = (props) => {
  const { className, highlight, entries = [] } = props

  return (
    <section className={className}>
      {highlight && (
        <ImageArticle
          rectangle
          bigTitle
          shadowedTitle
          noTitlePadding
          {...highlight}
          picture={{
            ...highlight.picture,
            placeholder: true,
            crop: {
              width: 16,
              height: 10,
            },
            sizes: '(min-width: 1920px) 920px, (min-width: 1200px) 50vw, 100vw',
          }}
        />
      )}

      <GridList>
        {GenerateImageArticleCollection({
          smallTitle: true,
          strokedTitle: true,
          picture: {
            placeholder: true,
            maxSize: 768,
            crop: {
              width: 1,
              height: 1,
            },
            sizes: '(min-width: 1920px) 300px, (min-width: 1200px) 15vw, (min-width: 720px) 30vw, 50vw',
          },
          entries,
          Wrapper: GridListEntry,
        })}
      </GridList>
    </section>
  )
}

export const SiteContentArticleHighlights = AsSiteContentEntry(ArticleHighlights)

export default ArticleHighlights
